'use client';
import {useTableParams} from "../../core/hooks/table-params";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {IInstitution, OrderListEntry, XmasPackageListEntry} from "../../core/interfaces";
import Loader from "../../core/components/Loader";
import {IColumn, Table} from "../../core/components/Table";
import {Pagination} from "../../core/components/Pagination";
import {Button} from "@mui/material";
import {OrdersSearchPaginationParams} from "../../core/services/utils";
import {Link} from "react-router-dom";
import {getAllOrders, getAllXmasPackages, getOrderInvoiceUrl} from "../../services/orders";

export const OrdersListPage: React.FC = (): JSX.Element => {
    const {
        page,
        setPage,
        size,
        setSize,
        totalElements,
        setTotalElements,
        sortBy,
        setSortBy,

    } = useTableParams({
        defaultSortBy: 'updatedAt',
    }
    )

    const [isError, setIsError] = useState<boolean>(false)
    const [showMessageBox, setShowMessageBox] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [orderType, setOrderType] = useState<string>('')
    const [orderStatus, setOrderStatus] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [orders, setOrders] = useState<OrderListEntry[]>([])
    const [isCheck, setIsCheck] = useState<string[]>([])
    const searchParams = useRef<{ [key: string]: string }>({})
    const timer = useRef<NodeJS.Timeout | null>(null)
    const [editMode, setEditMode] = useState<boolean>(false)
    const [currentInstitution, setCurrentInstitution] = useState<IInstitution>()
    const [showCreateInstitutionModal, setShowCreateInstitutionModal] = useState<boolean>(false)
    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const params: OrdersSearchPaginationParams = {
                page,
                size,
                sortBy,
                type: orderType,
                status: orderStatus
            };
            params.text = searchParams.current.orderDispNumber
            params.phone = searchParams.current.userPhone
            params.name = searchParams.current.userName
            params.type = orderType
            params.status = orderStatus
            const response = await getAllOrders(params)
            setTotalElements(response.data.totalElements)
            setOrders(response.data.content)
            setPage(page + 1)
        } catch (e) {
            if (e instanceof Error) {
                setIsError(true)
                setShowMessageBox(true)
                setMessage(e.message)
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }, [page, size, sortBy, orderType, orderStatus])

    useEffect(() => {
        getData()
    }, [page, size, sortBy, orderType, orderStatus])

    const handleSortBy = (sort: string) => {
        if (sort === sortBy) {
            setSortBy('updatedAt')
        } else {
            setSortBy(sort)
        }
    }

    const pageChange = async (newPage: number) => {
        setPage(newPage)
    }

    const handleSearchParams = useCallback(
        async (col: IColumn, q: string) => {
            if (timer.current) {
                clearTimeout(timer.current)
            }

            searchParams.current = {
                ...searchParams.current,
                [col.accessor]: q,
            }

            const newTimer = setTimeout(async () => {
                setLoading(true)
                try {
                    const params: OrdersSearchPaginationParams = {
                        page: 0,
                        size,
                        sortBy,
                    }

                    params.text = searchParams.current.orderDispNumber
                    params.phone = searchParams.current.userPhone
                    params.name = searchParams.current.userName
                    params.type = orderType
                    params.status = orderStatus
                    console.log(params);
                    const { data: res, status } = await getAllOrders(params)
                    if (status === 200) {
                        setOrders(res.content)
                        setTotalElements(res.totalElements)
                        setSize(res.size)
                        setPage(res.pageNumber + 1)
                    }
                } catch (err) {
                    console.log(err)
                } finally {
                    setLoading(false)
                }
            }, 2000)
            timer.current = newTimer
        },
        [page, size, sortBy, orderType, orderStatus],
    )

    function getInvoiceForOder(id: string) {
        getOrderInvoiceUrl(id).then(value => window.open(value.data, "_blank"));
    }

    const columns: IColumn[] = [
        {
            title: 'OrderNumber',
            accessor: 'orderDispNumber',
            sortable: true,
            searchable: true
        },
        {
            title: 'User Name',
            accessor: 'userName',
            sortable: true,
            searchable: true
        },
        {
            title: 'User phone',
            accessor: 'userPhone',
            sortable: true,
            searchable: true
        },
        {
            title: 'Order Type',
            accessor: 'type',
            sortable: false,
            searchable: false
        },
        {
            title: 'Full price',
            accessor: 'fullPrice',
            sortable: false,
            searchable: false
        },
        {
            title: 'Status',
            accessor: 'status',
            sortable: true,
            searchable: false
        },
        {
            title: 'Created At',
            accessor: '',
            render: (row) => (
                <span>{new Date(row.createdAt).toLocaleDateString("ro", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                })}</span>
            ),
            searchable: false
        },
        {
            title: 'View',
            accessor: '',
            render: (row) => (

                <Link to={`/orders/details/${row.id}`}>
                    <Button className="btn btn-secondary">
                        View Details
                    </Button>
                </Link>
            ),
        },
        {
            title: 'Get Invoice',
            accessor: '',
            render: (row) => (
                <Button className="btn btn-secondary" onClick={() => {getInvoiceForOder(row.id)}}>
                    Get Invoice
                </Button>
            ),
        }
    ]

    function modifyOrderType(value: string) {
        setOrderType(value);
        return;
    }

    function modifyStatus(value: string) {
        setOrderStatus(value);
        return;
    }


    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                {loading && <Loader/>}
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                >
                    <h2>Orders</h2>
                </div>
                <div>
                    Order Type:
                    <select id="orderType" onChange={(e) => modifyOrderType(e.target.value)}>
                        <option value="" selected>ALL</option>
                        <option value="SUBSCRIPTION">SUBSCRIPTION</option>
                        <option value="BAG">BAG</option>
                    </select>
                </div>

                <div>
                    STATUS:
                    <select id="orderType" onChange={(e) => modifyStatus(e.target.value)}>
                        <option value="" selected>ALL</option>
                        <option value="PAID">PAID</option>
                        <option value="REFUNDED">REFUNDED</option>
                        <option value="FAILED">FAILED</option>
                    </select>
                </div>
                <div className="overflow-x-auto">
                    <Table
                        handleSearch={handleSearchParams}
                        isCheck={isCheck}
                        columns={columns}
                        handleSortBy={handleSortBy}
                        data={orders}
                    />
                </div>
                <Pagination
                    size={size}
                    totalElements={totalElements}
                    setSize={setSize}
                    page={page + 1}
                    changePage={pageChange}
                />

            </div>
        </>
    )
}