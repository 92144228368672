'use client';
import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from "react";
import {IInstitutionGroup, XmasPackageDetails} from "../../core/interfaces";
import Loader from "../../core/components/Loader";
import {Link, useParams} from "react-router-dom";
import {ArrowLeft} from "@mui/icons-material";
import {finalizePackage, getXmasPackageDetails, replaceBook, setAwbOnPackage} from "../../services/orders";
import {Form} from "react-bootstrap";
import {map} from "lodash-es";
import Divider from '@mui/material/Divider';
import {Button} from "@mui/material";

export const XmasPackageAssignPage: React.FC = (): JSX.Element => {

    const {packageId} = useParams()
    const [loading, setLoading] = useState<boolean>(false)
    const [update, setUpdate] = useState<boolean>(true)
    const [awb, setAwb] = useState<string>('')
    const [xmasPackage, setXmasPackage] = useState<XmasPackageDetails>()
    const selection = useRef<HTMLFormElement>(null);

    const getData = useCallback(async () => {
        try {
            setLoading(true)
            const response = await getXmasPackageDetails(packageId)
            setXmasPackage(response.data)
        } catch (e) {
            if (e instanceof Error) {
            } else {
                console.error(e)
            }
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (update) {
            getData()
            setUpdate(false);
        }
    }, [update])


    function onchangeBook(instanceId: string, bookId: string) {
        replaceBook(packageId, instanceId, bookId).then(value => {
            setUpdate(true);
            selection?.current?.reset();
        });
    }

    function finalize() {
        finalizePackage(packageId)
            .then(value => {alert("Package finalized")})
            .finally(() => {setUpdate(true)});
    }

    function modifyAwb(e: ChangeEvent<HTMLInputElement>) {
        console.log(e.target.value);
        setAwb(e.target.value);
    }

    function triggerSetAwb() {
        setAwbOnPackage(packageId, awb)
            .then(value => {alert("AWB set")})
            .catch(reason => {alert("AWB set failed! Mai multe pachete in comanda.")})
            .finally(() => {setUpdate(true)});
    }

    return (
        <>
            <div
                style={{
                    padding: 20,
                    backgroundColor: '#F8F8F8',
                    position: 'relative',
                }}
            >
                {loading && <Loader />}
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 20,
                    }}
                    >
                    <h2>
                        <Link to={`/orders/christmas-package/list`}>
                            <ArrowLeft style={{backgroundColor: 'black', color: 'white', padding: '0px', width: '20px', height: '20px', textAlign: 'center', lineHeight: '1.1', borderRadius: '10px', marginRight: '10px'}}/>
                        </Link>
                        XMas Packages</h2>

                </div>
                <div >
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <div className="d-flex gap-2" style={{flexDirection:"column"}}>
                            <div className="row"><span className="">{xmasPackage?.name} ({xmasPackage?.ageGroupName})</span></div>
                            <div className="row"><span className="">Order: {xmasPackage?.orderDispNumber}</span></div>
                            <div className="row"><span className="">Order Note: {xmasPackage?.orderNote}</span></div>
                            <div className="row"><span className="">Pret: {xmasPackage?.amountPaid}</span></div>
                            <div className="row"><span className="">Utilizator: {xmasPackage?.userName} - {xmasPackage?.userPhone}</span></div>
                            <div className="row"><span className="">Pack Catalog price: {xmasPackage?.books.reduce((sum, book) => sum + book.selectedBook.catalogPrice, 0)}</span></div>
                            <div className="row"><span className="">Pack Bought price: {xmasPackage?.books.reduce((sum, book) => sum + book.selectedBook.boughtPrice, 0)}</span></div>
                            <div className="row"><span className="">Status: {xmasPackage?.status}</span></div>
                            <div className="row"><span className="">AWB: {xmasPackage?.awb}</span></div>
                        </div>
                        <div className="d-flex gap-2" style={{flexDirection: "column"}}>
                            <div className="row"><span className=""><b>Adresă de livrare</b></span></div>

                            <div className="row"><span
                                className="">{xmasPackage?.deliveryAddress.firstName} {xmasPackage?.deliveryAddress.lastName}</span>
                            </div>
                            <div className="row"><span className="">{xmasPackage?.deliveryAddress.email}</span></div>
                            <div className="row"><span className="">{xmasPackage?.deliveryAddress.phone}</span></div>
                            <div className="row"><span className="">{xmasPackage?.deliveryAddress.address}</span></div>
                            <div className="row"><span className="">{xmasPackage?.deliveryAddress.city}</span></div>
                            <div className="row"><span className="">{xmasPackage?.deliveryAddress.county}</span></div>
                            <div className={"row"}><span className="">{xmasPackage?.deliveryAddress.country}</span>
                            </div>
                            <div className="row"><span className="">{xmasPackage?.deliveryAddress.postCode}</span></div>
                        </div>
                    </div>
                    <Divider color="#0000FF"/>
                    <Form ref={selection}>
                    <div className="d-flex gap-2" style={{flexDirection:"row"}}>
                        {xmasPackage?.books.map((book, index) => (
                            <div key={index} className="row">
                                <div style={{margin: "auto"}}><img src={book?.selectedBook.url}
                                                                   style={{maxWidth: "250px", maxHeight: "250px"}}/>
                                </div>
                                <div className="row"><span className="">{book.selectedBook?.name}</span></div>
                                <div className="row"><span className="">{book.selectedBook?.catalogPrice}</span></div>
                                <div className="row"><span className="">{book.selectedBook?.boughtPrice}</span></div>
                                <div style={{margin: "auto", minHeight: "250px"}}>
                                    {xmasPackage?.status === 'AWB_ASSIGNED' ? <></>:
                                        <Form.Select className="d-flex flex-column mb-0"
                                                     defaultValue={'Select book'}
                                                     onChange={(e) => {
                                                         onchangeBook(book.selectedBook.instanceId, e.target.value);
                                                     }}
                                        >
                                            <option value={''} key={''}>
                                                Select Book
                                            </option>
                                            {map(book.suggestions, (bookie) => (
                                                <option id={bookie.bookId} key={bookie.bookId} value={bookie.bookId} selected={bookie.bookId === book.selectedBook.bookId}>
                                                    {bookie.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    }

                                </div>
                            </div>
                        ))}
                    </div>
                    </Form>
                    {xmasPackage?.status === 'PAID' ?
                        <>
                        <Button variant="contained" size="small" onClick={() => {
                            finalize();
                        }}>
                            <div className="d-flex align-items-center justify-content-center">
                                <i className="bi bi-arrow-right-square me-2 d-flex" />
                                <span>Mark as final</span>
                            </div>
                        </Button></>
                        :
                        <></>
                    }

                    {xmasPackage?.status === 'BOOKS_ASSIGNED' ?
                        <>
                            <input type="text" placeholder="awb" onChange={modifyAwb}/>
                            <Button variant="contained" size="small" onClick={() => {
                                triggerSetAwb();
                            }}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <i className="bi bi-arrow-right-square me-2 d-flex" />
                                    <span>Set AWB</span>
                                </div>
                            </Button></>
                        :
                        <></>
                    }

                </div>

            </div>
        </>
    )
}