import HomePage from "../pages/home/HomePage";
import {RouteType} from "./config";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

import {BookListPage} from "../pages/books/BookListPage";
import BooksPageLayout from "../pages/books/BooksPageLayout";
import BooksIndex from "../pages/books/BooksIndex";
import HelpersPageLayout from "../pages/helpers/HelpersPageLayout";
import HelpersIndex from "../pages/helpers/HelpersIndex";
import {CategoryListPage} from "../pages/helpers/CategoryListPage";
import {CollectionsListPage} from "../pages/helpers/CollectionsListPage";
import {IllustratorsListPage} from "../pages/helpers/IllustratorListPage";
import {PublishersListPage} from "../pages/helpers/PublisherListPage";
import {LanguagesListPage} from "../pages/helpers/LanguageListPage";
import {AuthorsListPage} from "../pages/helpers/AuthorsListPage";
import {AgeCategoriesListPage} from "../pages/helpers/AgeCategoryListPage";
import React from "react";
import {TranslatorsListPage} from "../pages/helpers/TranslatorListPage";
import {BookInstancesListPage} from "../pages/books/BookInstancesListPage";
import InstitutionsPageLayout from "../pages/institutions/InstitutionsPageLayout";
import InstitutionsIndex from "../pages/institutions/InstitutionsIndex";
import {InstitutionsListPage} from "../pages/institutions/InstitutionsListPage";
import {InstitutionGroupsListPage} from "../pages/institutions/InstitutionGroupsListPage";
import {InternalAgeGroupsListPage} from "../pages/helpers/InternalAgeGroupListPage";
import {PlansListPage} from "../pages/books/PlansListPage";
import {PlanGroupsListPage} from "../pages/books/PlanGroupsListPage";
import {GenresListPage} from "../pages/helpers/GenreListPage";
import {ReadingPlansListPage} from "../pages/plans/ReadingPlansListPage";
import {ReadingPlanGroupsListPage} from "../pages/plans/ReadingPlanGroupsListPage";
import {ImpersonatePage} from "../pages/helpers/ImpersonatePage";
import OrdersPageLayout from "../pages/orders/OrdersPageLayout";
import OrdersIndex from "../pages/orders/OrdersIndex";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {XmasPackageListPage} from "../pages/orders/XmasPackageListPage";
import {XmasPackageAssignPage} from "../pages/orders/XmasPackageAssignPage";
import {OrdersListPage} from "../pages/orders/OrdersListPage";
import {OrderDetailsPage} from "../pages/orders/OrderDetailsPage";

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <HomePage />,
    state: "home"
  },
  // {
  //   path: "/installation",
  //   element: <InstallationPage />,
  //   state: "installation",
  //   sidebarProps: {
  //     displayText: "Installation",
  //     icon: <FileDownloadOutlinedIcon />
  //   }
  // },
  {
    path: "/books",
    element: <BooksPageLayout />,
    state: "books",
    sidebarProps: {
      displayText: "Books",
      icon: <DashboardOutlinedIcon />
    },
    child: [
      {
        index: true,
        element: <BooksIndex />,
        state: "books.index"
      },
      {
        path: "/books/list",
        element: <BookListPage />,
        state: "books_list",
        sidebarProps: {
          displayText: "Books"
        },
      },
      {
        path: "/books/plans",
        element: <PlansListPage />,
        state: "template_plans_list",
        sidebarProps: {
          displayText: "Template Plans"
        },
      },
      {
        path: "/books/plans/:planId/groups",
        element: <PlanGroupsListPage />,
        state: "books_instance_list",
      },
      {
        path: "/books/:bookId/instances",
        element: <BookInstancesListPage />,
        state: "books_instance_list",
      }
    ]
  },
  {
    path: "/plans",
    element: <InstitutionsPageLayout />,
    state: "Plans",
    sidebarProps: {
      displayText: "Plans",
      icon: <AccountBalanceOutlinedIcon />
    },
    child: [
      {
        path: "/plans/:iGroupId/:planId",
        element: <ReadingPlanGroupsListPage />,
        state: "reading_plans_per_group",
      },
      {
        path: "/plans/institution-group/:iGroupId",
        element: <ReadingPlansListPage />,
        state: "reading_plans_per_group",
      }
    ]
  },
  {
    path: "/institutions",
    element: <InstitutionsPageLayout />,
    state: "institutions",
    sidebarProps: {
      displayText: "Institution",
      icon: <AccountBalanceOutlinedIcon />
    },
    child: [
      {
        index: true,
        element: <InstitutionsIndex />,
        state: "institutions.index"
      },
      {
        path: "/institutions/list",
        element: <InstitutionsListPage />,
        state: "institutions_list",
        sidebarProps: {
          displayText: "Institutions"
        },
        },
        {
          path: "/institutions/:instId/groups",
          element: <InstitutionGroupsListPage />,
          state: "institutions_list",
        }
      ]
    },
  {
    path: "/orders",
    element: <OrdersPageLayout />,
    state: "Orders",
    sidebarProps: {
      displayText: "Orders",
      icon: <MonetizationOnIcon />
    },
    child: [
      {
        index: true,
        element: <OrdersIndex />,
        state: "orders.index"
      },
      {
        path: "/orders/christmas-package/list",
        element: <XmasPackageListPage />,
        state: "christmas_package_list",
        sidebarProps: {
          displayText: "Christmas Packages"
        },
      },
      {
        path: "/orders/christmas-package/:packageId",
        element: <XmasPackageAssignPage />,
        state: "christmas_package_assign",
      },
      {
        path: "/orders/list",
        element: <OrdersListPage />,
        state: "orders_list",
        sidebarProps: {
          displayText: "Orders List"
        },
      },
      {
        path: "/orders/details/:orderId",
        element: <OrderDetailsPage />,
        state: "order_details_assign",
      }
    ]
  },
    {
      path: "/helpers",
      element: <HelpersPageLayout/>,
      state: "helpers",
      sidebarProps: {
        displayText: "Helpers",
        icon: <AppsOutlinedIcon/>
      },
      child: [
        {
          index: true,
            element: <HelpersIndex/>,
        state: "helpers.index"
      },
      {
        path: "/helpers/authors",
        element: <AuthorsListPage />,
        state: "authors_list",
        sidebarProps: {
          displayText: "Authors"
        },
      },
      {
        path: "/helpers/categories",
        element: <CategoryListPage />,
        state: "category_list",
        sidebarProps: {
          displayText: "Categories"
        },
      },
        {
          path: "/helpers/genres",
          element: <GenresListPage />,
          state: "genres_list",
          sidebarProps: {
            displayText: "Genres"
          },
        },

      {
        path: "/helpers/age-categories",
        element: <AgeCategoriesListPage />,
        state: "age_category_list",
        sidebarProps: {
          displayText: "Age Categories"
        },
      },
        {
          path: "/helpers/internal-age-groups",
          element: <InternalAgeGroupsListPage />,
          state: "internal_age_group_list",
          sidebarProps: {
            displayText: "Internal Age Groups"
          },
        },
      {
        path: "/helpers/collections",
        element: <CollectionsListPage />,
        state: "collections_list",
        sidebarProps: {
          displayText: "Book Collections"
        },
      },
      {
        path: "/helpers/illustrators",
        element: <IllustratorsListPage />,
        state: "illustrators_list",
        sidebarProps: {
          displayText: "Illustrators"
        },
      },
      {
        path: "/helpers/publishers",
        element: <PublishersListPage />,
        state: "publishers_list",
        sidebarProps: {
          displayText: "Publishers"
        },
      },
      {
        path: "/helpers/translators",
        element: <TranslatorsListPage />,
        state: "translators_list",
        sidebarProps: {
          displayText: "Translators"
        },
      },
      {
        path: "/helpers/languages",
        element: <LanguagesListPage />,
        state: "languages_list",
        sidebarProps: {
          displayText: "Languages"
        },
      },
      {
        path: "/helpers/impersonate",
        element: <ImpersonatePage />,
        state: "impersonate",
        sidebarProps: {
          displayText: "Impersonate"
        },
      }

    ]
  }
];

export default appRoutes;